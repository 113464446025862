/* Example styles for the modal */
.ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .ReactModal__Content {
    width: 50%;
    max-height: 80%;
    margin-top: 100px;
    margin-left: 450px;
    border: none;
  }
  
  .ReactModal__Content img {
    max-width: 100%;
    height:100vh;
  }
  
  
  .ReactModal__Content button {
  
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    cursor: pointer;
  }

  small{
    font-size: 13px;
    font-family: sans-serif;
  }

  .button-group {

    margin-right: 25px
 
   }
 
  
 
   .button-excel {
 
     background-color: rgb(204, 204, 204);
 
     color: #000;
 
     padding: 8px 16px;
 
     border: none;
 
     border-radius: 15px 0px 0px 15px;
 
     cursor: pointer;
 
   }
 
 
 
 
   .button-csv {
 
     background-color: rgb(204, 204, 204);
 
     color: #000;
 
     padding: 8px 16px;
 
     border: none;
 
     cursor: pointer;
 
   }
 
  
 
   .button-pdf {
 
     background-color: rgb(204, 204, 204);
 
     color: #000;
 
     padding: 8px 16px;
 
     border: none;
 
     border-radius: 0px 15px 15px 0px;
 
     cursor: pointer;
 
   }

   /* .YpOAo svg {
    top: 10px !important;
} */
  