.calendar-container .fc-daygrid-body {
    border: 1px solid #ddd; /* Add a border around the calendar */
  }
  
  .calendar-container .fc-daygrid-body tr {
    border-bottom: 1px solid #ddd; /* Add a border at the bottom of each row */
  }
  
  .calendar-container .fc-daygrid-body td {
    border-right: 1px solid #ddd; /* Add a border at the right of each cell */
  }

  /* calendar.css */
.calendar-container {
    padding: 15px; /* Add padding around the calendar */
    background-color: #f0f0f0; /* Set the background color of the calendar */
  }
  
  /* calendar.css */
.calendar-container .fc-daygrid-body {
    border: 1px solid #ddd; /* Add a border around the calendar */
  }
  
  .calendar-container .fc-daygrid-body td {
    border-right: 1px solid #ddd; /* Add a border at the right of each cell */
    border-bottom: 1px solid #ddd; /* Add a border at the bottom of each cell */
  }
/* calendar.css */

 
.has-events {
  background-color: red !important; /* Change the background color to red */
  border-radius: 5px; /* Add border radius for rounded corners */
  padding: 8px; /* Add padding for thicker background */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.thick-background {
  background-color: red; /* Specify the desired background color */
  padding: 2px; /* Adjust padding to increase the thickness */
  margin-left: 0px;
  width: 154px;
  margin-top:0px;
height: 62px;
}

.event-container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  text-align: justify;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 10px;
  border-radius: 6px;
  border-left: 6px solid #F69727;
}

.event-title {
  color: #A90000;
}

.event-details {
  color: #000000;
}


.fc-daygrid-day {
  cursor: pointer;
}

/*  */
.fc .fc-button-primary{
  background-color: rgb(246, 151,39); 
}

.fc .fc-button-group > .fc-button { 
  font-size: 9px; 
}
