.not_found{
    background-color: #000;
    height: 100vh;
}

#not_found_col_id{
    padding: 200px
}

#not_found_col_col_id{
    color: #f1963b;
    font-size: 74.5px;
    text-align: center;
}

#text_tag{
    color: #f1963b;
}