.menu-bg1{
    overflow: auto;
    max-height: calc(100vh - 70px);
    

}

.menu-bg1::-webkit-scrollbar {
    background: transparent; 
    width: 0px;
}

/* .menu-link.active {
  background-color: #f0f0f0; 
  color: #000;  
} */

.menu-open .menu-dropdown {
    display: block;
  }