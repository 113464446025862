body {
  margin: 0;
  /* font-family: "Inter", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Css for All Downloads Format */
.button-group {
  margin-right: 25px
 }
 
.button-excel-format {
   background-color: rgb(204, 204, 204);
   color: #000;
   padding: 8px 16px;
   border: none;
   border-radius: 15px 0px 0px 15px;
   cursor: pointer;
}

.button-csv-format {
   background-color: rgb(204, 204, 204);
   color: #000;
   padding: 8px 16px;
   border: none; 
   cursor: pointer;
}
 
.button-pdf-format {
   background-color: rgb(204, 204, 204);
   color: #000;
   padding: 8px 16px;
   border: none;
   border-radius: 0px 15px 15px 0px;
   cursor: pointer; 
}
.Card_form {
   background-color: #fff;
   padding: 1rem;
 }
.edit_img {
   position: relative;
}
.edit_img img {
   position: absolute;
   right: 0;
}
.position_relative {
   position: relative;
}
.position_relative .span_p {
   position: absolute;
   right: 0;
}
.bank_img_main {
   text-align: center;
   margin-bottom: 1rem;
}
.bank_img_main img {
   width: 80px;
}
.Bank_deatils_main {
   padding: 5rem !important;
}
.table_tbody tr td {
   border: 1px solid #c3c6d0;;
}
.calender_icon {
   position: relative;
}
.calender_icon i {
   position: absolute;
   right: 0px;
}
.calender_icon svg {
   position: absolute;
   font-size: 18px;
   color: #ccc;
   bottom: 10px;
   right: 10px;
}
 
