.button-container{
    background-color: #A90000;
    color: #fff;
    width: 350px;
    height: 40px;
    margin-top: -35px;
    display:flex;
    justify-content:center;
    align-items:center;
    border: none;
    border-radius: 5px;
}