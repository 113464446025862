.centered-content {
    vertical-align: middle;
  }
  .btnimg{
    background:red;
    pointer-events:none;
    width:250px;
    height:90px;
    opacity:1;
    line-height:90px;
    text-align:center;
  }
  
  input[type=file]{
    top:0;
    left:0;
    right:0;
    bottom:0;
  }